import fetch from 'cross-fetch';
import { Form, Formik, FormikProps } from 'formik';
import Cookies from 'js-cookie';
import { createRef, Dispatch, FC, SetStateAction } from 'react';
import tw, { css } from 'twin.macro';
import * as Yup from 'yup';
import { isSSG } from '~/config';
import ArrowIcon from '~/images/arrow-right.svg';
import { LinkPlus } from '../link-plus';
import { NERLYNX } from '../typography';
import { NormalField } from './normal-field';

type ContactFormProps = {
  submitErrored?: boolean;
  setSubmitState: Dispatch<SetStateAction<SubmitState>>;
  submitState: SubmitState;
};

export type ContactFormData = {
  firstname: string;
  lastname: string;
  email: string;
  confirm_email: string;
  zip: string;
  provider_type: string;
  job_title?: string;
  company: string;
};

const initialValues: ContactFormData = {
  firstname: '',
  lastname: '',
  email: '',
  confirm_email: '',
  zip: '',
  provider_type: '',
  job_title: '',
  company: '',
};

// Validates both 5 and 9 digit zip codes separated by dash
const ZIPCODE_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const today = new Date();

// const SAME_EMAIL = CHECK THAT confirm_email === email;
const schema = Yup.object().shape({
  firstname: Yup.string().required('Please enter a first name.'),
  lastname: Yup.string().required('Please enter a last name.'),
  email: Yup.string()
    .email('Please enter a valid email address.')
    .required('Please enter an email address.')
    .matches(EMAIL_REGEX, 'Please enter a valid email address.'),
  confirm_email: Yup.string()
    .oneOf([Yup.ref('email'), ''], 'Emails must match.')
    .required('Please enter an email address.'),
  zip: Yup.string()
    .required('Please enter a zip code.')
    .matches(ZIPCODE_REGEX, 'Please enter a valid zip code.'),
  provider_type: Yup.string().required('Please specify type of provider.'),
  job_title: Yup.string().when('provider_type', {
    is: 'Other',
    then: (field) => field.required('Please specify other type of provider.'),
    otherwise: (field) => field.notRequired(),
  }),
  company: Yup.string().required('Please enter a practice/organization.'),
});

const options = {
  provider: ['MD', 'DO', 'NP', 'PharmD', 'MA', 'PA', 'RN', 'Other'],
};

export type SubmitState = 'none' | 'submitting' | 'errored' | 'succeeded';

/**
 * Request information form
 */
export const ContactForm: FC<ContactFormProps> = ({
  setSubmitState,
  submitState,
  submitErrored,
}) => {
  const formikRef = createRef<FormikProps<ContactFormData>>();

  const handleSubmit = async (values: ContactFormData, { setSubmitting }) => {
    const hutk = !isSSG ? Cookies.get('hubspotutk') : null; // The Hubspot plugin provides this hutk value as a cookie.
    const pageUri = !isSSG ? window.location.href : null;
    const pageName = !isSSG ? document.title : null;

    const body = {
      submittedAt: today,
      fields: [
        {
          objectTypeId: '0-1', //contact fields have objecttypeid 0-1
          name: 'firstname',
          value: values.firstname,
        },
        {
          objectTypeId: '0-1',
          name: 'lastname',
          value: values.lastname,
        },
        {
          objectTypeId: '0-1',
          name: 'email',
          value: values.email,
        },
        {
          objectTypeId: '0-1',
          name: 'confirm_email',
          value: values.confirm_email,
        },
        {
          objectTypeId: '0-1',
          name: 'zip',
          value: values.zip,
        },
        {
          objectTypeId: '0-1',
          name: 'provider_type',
          value: values.provider_type,
        },
        {
          objectTypeId: '0-1',
          name: 'job_title',
          value: values.job_title,
        },
        {
          objectTypeId: '0-1',
          name: 'company',
          value: values.company,
        },
      ],
      context: {
        hutk,
        pageUri,
        pageName,
      },
    };

    setSubmitState('submitting');

    try {
      const response = await fetch('/api/contact', {
        method: 'post',
        body: JSON.stringify(body),
      });

      if (response.ok) {
        setSubmitState('succeeded');
      } else {
        setSubmitState('errored');
        console.error('Submission failed', response.body);
      }
    } catch (e) {
      setSubmitState('errored');
      console.error('Submission failed', e);
    }

    scrollTo(0, 360); // top of content section. Hero is set to 360px in height. If hero changes this will need to be updated.

    // formik callback - unless unmounted
    if (formikRef?.current) {
      setSubmitState('none');
      setSubmitting(false);
    }
  };

  return (
    <FormWrapper>
      <Intro>
        <p>
          Please complete the form below to contact a <NERLYNX />{' '}
          representative.
        </p>
      </Intro>

      <Formik
        validateOnMount
        validateOnBlur
        validateOnChange
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <StyledForm>
            {submitErrored && !!formik.isValid && (
              <FullWidthField tw="text-red-500 text-center">
                An error occurred while submitting this form. Please wait a
                moment and try again.
              </FullWidthField>
            )}
            <NormalField
              id="firstname"
              name="firstname"
              type="text"
              label="First name"
              placeholder="First Name"
              formik={formik}
              required
              forceError={submitState === 'errored' && !formik.values.firstname}
            />

            <NormalField
              id="lastname"
              name="lastname"
              type="text"
              label="Last name"
              placeholder="Last Name"
              formik={formik}
              required
              forceError={submitState === 'errored' && !formik.values.lastname}
            />

            <NormalField
              id="email"
              name="email"
              type="email"
              label="Email Address"
              placeholder="Email Address"
              required
              formik={formik}
              className="peer"
              forceError={submitState === 'errored' && !formik.values.email}
            />

            <NormalField
              id="confirm_email"
              name="confirm_email"
              type="email"
              label="Confirm Email Address"
              formik={formik}
              placeholder="Confirm Email Address"
              required
              className="peer"
              forceError={
                submitState === 'errored' && !formik.values.confirm_email
              }
            />

            <NormalField
              id="zip"
              name="zip"
              type="text"
              label="Zip Code"
              formik={formik}
              placeholder="Zip Code"
              required
              className="peer"
              customCss={tw`md:mb-0`} //keeps 'other' field from displaying too low
              forceError={submitState === 'errored' && !formik.values.zip}
            />

            <NormalField
              id="provider_type"
              name="provider_type"
              label="Provider Type"
              formik={formik}
              required
              options={options.provider}
              selectPlaceholder="Select"
              className="peer"
              customCss={tw`my-0!`} //keeps 'other' field from displaying too low
              forceError={
                submitState === 'errored' && !formik.values.provider_type
              }
            />

            {formik.values.provider_type === 'Other' && (
              <>
                <NormalField
                  id="job_title"
                  name="job_title"
                  type="text"
                  formik={formik}
                  placeholder="Please Specify"
                  required
                  className="peer"
                  customCss={[
                    tw`mb-0 md:(col-start-2 my-0!)`,
                    css`
                      input {
                        margin-top: 0;
                      }
                    `,
                  ]}
                  forceError={
                    submitState === 'errored' && !formik.values.job_title
                  }
                />
              </>
            )}

            <FullWidthField tw="mb-0 mt-12">
              <NormalField
                id="company"
                name="company"
                type="text"
                label="Practice/Organization"
                placeholder="Practice/Organization"
                formik={formik}
                required
                className="peer"
                forceError={submitState === 'errored' && !formik.values.company}
                customCss={tw`mb-8! md:mb-4!`}
              />
            </FullWidthField>

            <FullWidthField>
              All fields are required. All information collected will be used
              for sending updates only and will not be shared with third
              parties. By providing your email you are agreeing to receive
              communications. For more details, see our{' '}
              <LinkPlus to="https://www.pumabiotechnology.com/privacy_policy_20240301.html">
                Privacy Policy
              </LinkPlus>
              .
            </FullWidthField>

            <Button
              type="submit"
              onClick={() => {
                formik.isValid ? handleSubmit : setSubmitState('errored');
              }}
            >
              Submit <ArrowIcon tw="inline-block self-center ml-2 xs:ml-3" />
            </Button>
          </StyledForm>
        )}
      </Formik>
    </FormWrapper>
  );
};

const StyledForm = tw(
  Form,
)`grid gap-4 [grid-template-columns: 1fr] md:[grid-template-columns: 1fr 1fr] max-w-full`;

const Button = tw.button`bg-brand-cyan text-brand-white [font-size: 16px;] [line-height: 0.75rem;] 
                py-4 px-6 rounded-full justify-self-center w-[168px] h-[48px] hover:bg-brand-pink  mt-8 md:(mt-4 [grid-column: 1/3]) lg:mt-8`;
const Intro = tw.div`mb-8 md:mb-12`;
const FullWidthField = tw.div`md:([grid-column: 1/3])`;

const FormWrapper = tw.div`mt-8 mb-10 font-primary md:mb-24`;
