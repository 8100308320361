import { PageProps } from 'gatsby';
import { FC, useState } from 'react';
import tw from 'twin.macro';
import { ContentSection, Hero, HR, Layout, NERLYNX, SEO } from '~/components';
import { ContactForm, SubmitState } from '~/components/common/contact-form';
import { Frontmatter } from '~/config';
import heroBackground from '~/images/hero-laptop.jpg';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Request Information',
  order: 102,
  screenShoot: true,
};

/**
 * Page to house hubspot form
 */
const Page: FC<PageProps> = (props) => {
  const [submitState, setSubmitState] = useState<SubmitState>('none');

  return (
    <Layout>
      <SEO
        title="Request Information | NERLYNX® (neratinib) tablets"
        description="Contact us to request more information about NERLYNX"
        ogDescription="Contact us to request more information about NERLYNX"
        path={props.location?.pathname}
      />
      <Hero
        backgroundImage={heroBackground}
        characterWidth={50}
        headlinePadding="right"
        backgroundStyle={tw`[background-position: 80%] bg-cover md:(bg-right)`}
      >
        Contact us to request more information
      </Hero>

      <HR yellow />

      <ContentSection>
        <TopSpacer />
        {submitState !== 'succeeded' ? (
          <ContactForm
            setSubmitState={setSubmitState}
            submitErrored={submitState === 'errored'}
            submitState={submitState}
          />
        ) : (
          <div tw="mb-6 md:mb-24">
            <p tw="text-brand-cyan text-center [font-size: 56px;] font-secondary mb-8 md:mb-16">
              Thank you!
            </p>
            <p>
              Thank you for your interest in connecting with a <NERLYNX />{' '}
              representative. A confirmation has been sent to your email
              address. We've received your request and will be in touch.
            </p>
          </div>
        )}
      </ContentSection>
    </Layout>
  );
};

export default Page;

const TopSpacer = tw.div`h-4 md:h-8`;
