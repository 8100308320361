import { Field, FormikProps } from 'formik';
import { FC, HTMLInputTypeAttribute, ReactNode, useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { CustomCssProps } from '~/config';
import ArrowDown from '~/images/down-arrow.svg';
import ErrorSVG from '~/images/error.svg';

export type NormalFieldProps = CustomCssProps & {
  label?: string;
  name: string;
  /** Helps present the correct keyboard on mobile */
  type?: HTMLInputTypeAttribute;
  options?: string[];
  required?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  forceError?: boolean;
  disableError?: boolean;
  id?: string /** to prevent duplicate input ids when two forms are on same page (eg. request a demo page) */;
  placeholder?: string;
  selectPlaceholder?: string;
  className?: string;
};

export const NormalField: FC<NormalFieldProps> = ({
  options,
  formik,
  forceError,
  disableError,
  required,
  id,
  name,
  type,
  label,
  placeholder,
  selectPlaceholder,
  className,
  customCss,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const errored = forceError || !!(formik.errors[name] && formik.touched[name]);

  const isSelect = options && options.length > 0;
  let optionsList: ReactNode[] = [
    <option
      tw="text-gray-300"
      key="select-placeholder"
      value=""
      disabled
      defaultValue={selectPlaceholder}
    >
      {selectPlaceholder}
    </option>,
  ];
  if (isSelect) {
    options.forEach((option) =>
      optionsList.push(<option key={option}>{option}</option>),
    );
  }

  return (
    <FieldContainer css={customCss}>
      <Label htmlFor={id || name} className="peer">
        {label && <LabelSpan errored={errored}>{label}</LabelSpan>}
        <Field
          required={required}
          id={id || name}
          name={name}
          css={[fieldStyles, errored && errorFieldStyles]}
          type={isSelect ? undefined : type} // select uses 'as' prop
          as={isSelect ? 'select' : undefined}
          // hardcoded for now, could add a prop if needed
          maxLength={100}
          // hardcodes limited support for common specific types, could add a prop if needed
          autoComplete={type === 'email' || type === 'tel' ? type : 'on'}
          placeholder={placeholder}
          disabled={formik.isSubmitting}
          className={className}
          onFocus={() => {
            // otherwise wait to set touched until blurred
            setIsFocused(true);
          }}
          onBlur={(e) => {
            // Remove all whitespace on blur (loss of focus) except for single space
            // between valid characters to account for full names, etc.
            const val = (e.target.value || '').replace(/\s+/gi, ' ').trim();
            formik.setFieldValue(name, val);
            formik.setFieldTouched(name);
            setIsFocused(false);
          }}
        >
          {isSelect ? optionsList : null}
        </Field>

        {errored && (
          <>
            <ErrorSpan>
              <>{formik.errors[name] || 'This is a required field'}</>
            </ErrorSpan>
          </>
        )}
      </Label>
      {errored && (
        <ErrorSVG
          css={[
            tw`absolute top-[55px] right-[23px] -z-10`,
            name === 'job_title' && tw`top-[17px]!`,
          ]}
        />
      )}
      {isSelect && !errored && (
        <ArrowDown tw="absolute top-[58px] right-[23px] -z-10" />
      )}
    </FieldContainer>
  );
};

const FieldContainer = tw.div`mb-12 relative md:mb-8`;
const Label = tw.label`text-brand-dark-blue uppercase font-bold [font-size: 24px] leading-none peer-invalid:text-red-500`;
const LabelSpan = styled.span<{ errored?: boolean }>(({ errored }) => [
  tw`text-brand-dark-blue uppercase font-bold leading-none mb-4 [font-size: 24px] md:[font-size: 21px] lg:[font-size: 24px] `,
  errored && tw`text-red-500`,
]);
const ErrorSpan = tw.span`[font-size: 15px] [line-height: 21px] text-red-500 [text-transform: none] font-normal`;

const fieldStyles = css`
  background-color: transparent;
  border: 1px solid rgb(var(--brand-gray));
  border-radius: 9999px;
  padding: 1rem;
  width: 100%;
  font-size: 16px;
  line-height: 21px;
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
  height: 55px;
  /* remove select arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  */ &::-ms-expand {
    /* display: none; */
    margin-right: 1em;
  }
  /* round the focus on safari */
  :focus::before {
    content: '';
    position: absolute;
    top: -3px; // border: 1px + offset: 2px
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px dashed gray;
    border-radius: 9999px;
  }
`;

const errorFieldStyles = css`
  border: 1px solid red;
`;
